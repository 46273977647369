import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../PageContext";
import { SearchBox } from "./SearchBox";
import * as styles from "./SearchBox.module.scss";

const SearchBoxConnector = ({ data, language, type: providedType }) => {
	const ctx = useContext(PageContext);
	const [isLoading, setIsLoading] = useState(true);
	const alias = ctx?.alias?.alias || data?.alias || "drv";
	let type = data?.type || providedType;
	switch (type) {
		case "other":
		case "lastminute":
		case "small groups":
			type = "packages";
			break;
		default:
			break;
	}
	return (
		<div
			className={styles.searchBoxRoot}
			data-mode={data?.mode || "search"}
			data-type={type}
			data-loading={isLoading}
		>
			{!ctx?.aliasLoading && (
				<SearchBox
					language={language}
					alias={alias}
					type={type}
					mode={data?.mode || "search"}
					setIsLoading={setIsLoading}
				/>
			)}
		</div>
	);
};

SearchBoxConnector.propTypes = {
	data: PropTypes.object.isRequired,
	language: PropTypes.string,
	type: PropTypes.oneOf([
		"packages",
		"flights",
		"hotels",
		"cruises",
		"other",
		"lastminute",
		"small groups",
	]),
};

SearchBoxConnector.defaultProps = {
	language: "en",
	type: "packages",
};

export default SearchBoxConnector;
export { SearchBoxConnector };
